import { createContext, forwardRef, useContext, useMemo } from "react";
import type { ClassProp, ClassValue, VariantProps } from "tailwind-variants";
import { tv } from "tailwind-variants";

import { useMatches } from "@remix-run/react";

import { cn, useIsOffScreen } from "~/lib/ui";

import { P } from "../ui/text";

export const MEGANAV_DEFAULT_VARIANT = "light";

const variant = tv({
  slots: {
    main: " bg-brand-primary-black sm-max:hover:bg-transparent",
    activated: "bg-contrast bg-opacity-100",
    focused: "",
  },
  variants: {
    text: {
      light: {
        main: "text-white sm-max:hover:text-white sm-max:focus-within:text-white",
        activated: "text-inherit ",
      },
      dark: {
        main: "text-inherit ",
        activated: "text-inherit ",
      },
    },
  },
});

const Context = createContext<{
  handle: NonNullable<MeganavTheme>;
  main: (slotProps?: ClassProp<ClassValue> | undefined) => string;
  activated: (slotProps?: ClassProp<ClassValue> | undefined) => string;
  focused: (slotProps?: ClassProp<ClassValue> | undefined) => string;
  boundryRef: React.RefObject<HTMLElement>;
}>(null as any);

export const useMeganav = () => {
  const ctx = useContext(Context);
  if (!ctx) throw new Error("useMeganav must be used inside a MeganavProvider");
  return ctx;
};

export const Root = ({
  children,
  boundryRef,
}: {
  children: React.ReactNode;
  boundryRef: React.RefObject<HTMLElement>;
}) => {
  const handle = useMenuHandle();
  const { main, activated, focused } = variant({
    text: handle.variant ?? MEGANAV_DEFAULT_VARIANT,
  });
  return (
    <Context.Provider
      value={{
        handle,
        main,
        activated,
        focused,
        boundryRef,
      }}
    >
      {children}
    </Context.Provider>
  );
};
Root.displayName = "Nav.Root";

export const Content = forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<"div">
>(({ children, className }, ref) => {
  const { main, activated, focused, boundryRef } = useMeganav();
  const isAfterBoundry = useIsOffScreen(boundryRef);

  return (
    <div
      id="nav-content"
      ref={ref}
      className={cn(
        "min-h-nav sticky top-0 z-50 transition-[background-color] duration-500",
        main(),
        focused(),
        isAfterBoundry && activated(),
        className,
      )}
    >
      {children}
    </div>
  );
});
Content.displayName = "Nav.Content";

export const PromoHeading = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ children, className }, ref) => {
  return (
    <div className={cn("top-0 flex h-[16px]", className)} ref={ref}>
      {children}
    </div>
  );
});
PromoHeading.displayName = "Nav.PromoHeading";

export const Header = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ children, className }, ref) => {
  return (
    <header
      className={cn(
        "top-0 mx-auto grid max-h-nav min-h-navmin w-fit max-w-1920 grid-cols-2 items-center gap-y-2 bg-brand-primary-black px-6 py-2 md:grid-cols-[180px_minmax(320px,_1fr)_180px] lg:grid-cols-[150px_minmax(650px,_1fr)_400px] lg:px-10 lg:py-3 xl:gap-14 xl:px-24  ",
        className,
      )}
      ref={ref}
    >
      {children}
    </header>
  );
});
Header.displayName = "Nav.Header";

export const Search = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ children, className }, ref) => {
  return (
    <div className={cn("w-fit flex-auto", className)} ref={ref}>
      {children}
    </div>
  );
});
Search.displayName = "Nav.Search";

export const MenuContent = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ children, className }, ref) => {
  return (
    <nav
      id="blurrable-content"
      className={cn(
        "left-0 top-0 flex h-14 w-auto flex-row items-center bg-brand-primary-black sm:hidden md:hidden",
        className,
      )}
      ref={ref}
    >
      {children}
    </nav>
  );
});
MenuContent.displayName = "Nav.MenuContent";

export const NavBoxVariant = tv({
  base: "absolute sm-max:-top-[70px] top-4 pl-4 flex",
  variants: {
    position: {
      left: "left-0 justify-start ",
      right: "right-0 justify-end ",
    },
  },
});

export const NavBox = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & VariantProps<typeof NavBoxVariant>
>(({ children, position, className }, ref) => {
  return (
    <nav className={NavBoxVariant({ position, className })} ref={ref}>
      {children}
    </nav>
  );
});
NavBox.displayName = "Nav.NavBox";

export const ListItem = ({
  title,
  children,
}: {
  className?: string;
  title: string;
  children: React.ReactNode;
}) => {
  return (
    <li className="p-3">
      <P size="fine" className="font-normal leading-tight">
        {title}
      </P>
      <>{children}</>
    </li>
  );
};
ListItem.displayName = "Nav.ListItem";

export const Boundry = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ children, ...props }, ref) => {
  return (
    <div ref={ref} {...props}>
      {children}
    </div>
  );
});
Boundry.displayName = "Nav.Boundry";

const useMenuHandle = (): NonNullable<MeganavTheme> => {
  const matches = useMatches();
  const match = useMemo(
    () =>
      matches
        .reverse()
        .filter(match => (match as any).handle?.["meganav"])
        .pop(),
    [matches],
  );
  const handle = (match as any)?.handle?.["meganav"];
  if (typeof handle === "function") {
    return handle((match as any).data);
  }
  return handle || ({} satisfies MeganavTheme);
};

type MeganavVariant = VariantProps<typeof variant>["text"];
type MeganavTheme = { variant?: MeganavVariant };
export type MeganavHandle = MeganavTheme | ((data: any) => MeganavTheme);
